<script setup lang="ts">
type Props = {
    errorMsg?: string;
    whiteBackground?: boolean;
    showTips?: boolean;
};

withDefaults(defineProps<Props>(), {
    errorMsg: '',
    showTips: true,
    whiteBackground: false,
});

const { t } = useLang();
</script>

<template>
    <div
        class="flex h-full w-full flex-col items-center justify-center gap-5 p-4 sm:pt-9"
        :class="{
            'bg-white': whiteBackground,
        }"
        data-testid="RequestErrorPage"
    >
        <IllustrationNoResult
            class="mx-auto max-w-[438px]"
            data-testid="RequestErrorPage Illustration"
        />
        <span
            class="px-7 text-center text-gray-900"
            data-testid="RequestErrorPage Text"
        >
            {{ errorMsg || t('notification', 'somethingWentWrong') }}
        </span>
        <BaseNotificationBox
            v-if="showTips"
            data-testid="RequestErrorPage Tips"
            type="info"
            class="w-max max-w-full p-5"
        >
            <span data-testid="RequestErrorPage Tips Heading">{{
                t('label', 'youCanDoTheFollowing')
            }}</span>
            <ul class="mt-2 list-disc pl-6">
                <li class="mb-2 pl-3">
                    {{ t('label', 'checkYourInternetConnection') }}
                </li>
                <li class="mb-2 pl-3">
                    {{ t('label', 'tryReload') }}
                </li>
            </ul>
        </BaseNotificationBox>
    </div>
</template>
